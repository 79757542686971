import { Buffer } from 'buffer';
import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
// const fs = require('fs');
//const http = require('http');
//const https = require('https');
//// or
//const axios = require('axios');

// const request = require('request')

// const express = require('express');

// const app = express();
// require("dotenv").config();

// const cors = require('cors');
// const { default: axios } = require('axios');


// app.listen(process.env.PORT, () => {
//    console.log(`Server is running on port ${process.env.PORT}`);
// });


// app.use(express.json());
// app.use(express.urlencoded({ extended: true }));
// app.use(cors());

// app.get('/', (req, res) => {
//    res.send('Hello World');
// });

//app.get('/token', (req, res) => {
//   generateToken()
//})

// ---


// Use cors middleware
//app.use(cors());

//app.get('/', (req, res) => {
//  res.send('CORS is enabled!');
//});

//app.listen(3000, () => {
//  console.log('Server running on port 3000');
//});









// let phoneNo;
// let token;
// let outputObject;
// let isPaymentSuccessful = false;

// const handlePayButtonClick = () => {
//   fetch('http://localhost:5000/token')
//   .then(response => response.json())
//   .then(data => {
//     token = data;
//   })
//   .catch(error => {
//     console.error('Error:', error);
//   });
  
//   const data ={
//     phone: phoneNo,
//     amount: 1,
//     // amount: calculateTotalPremium()
//   }
//   fetch('http://localhost:5000/stk', {
//     method: 'post',
//     headers: new Headers({
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }),
//     body:JSON.stringify(data)
//   })
//   .then(response => response.json())
//   .then(data => {
//     console.log('Success:', data);
//     console.log(data.ResponseCode);

//     outputObject = data;
//     isPaymentSuccessful = true;
//   })

// };





const Step4MpesaScreen = ({ }) => {
  let phoneNo;
  let token;
  let outputObject;
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  
  const handlePayButtonClick = () => {
    fetch('http://localhost:5000/token')
    .then(response => response.json())
    .then(data => {
      token = data;
    })
    .catch(error => {
      console.error('Error:', error);
    });
    
    const data ={
      phone: phoneNo,
      amount: 1,
      // amount: calculateTotalPremium()
    }
    fetch('http://localhost:5000/stk', {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      body:JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      console.log(data.ResponseCode);
  
      outputObject = data;
    })
    setIsPaymentSuccessful(true);
  
  };
  



  
  return (
  <div>
    //  {!isPaymentSuccessful &&
      <form>
        <div>
          <label className="font-semibold">Pay to Birdview Microinsuarance account</label>
        </div>
        <p>1. Provide your mpesa mobile number below.</p>
        <p>2. Click Pay and a prompt will appear on your phone requesting you to confirm transaction by providing your MPESA PIN.</p>
        <p>3. Once completed, you will receive the confirmation SMS for this transaction</p>

        <div className="my-5">
          <p>Provide your mpesa(KE) mobile number:</p>
          <div className="flex flex-row items-center my-2">
            <label className="mx-2">+254</label>
            <TextField
                          id=""
                          label="7XXXXXXXX"
                          variant="outlined"
                          fullWidth
                          value={phoneNo}
                          onChange={(e) => { phoneNo = e.target.value }}
              // error={}
              // helperText={}
            />
            <button
                type="button"
                style={{ backgroundColor: '#E42D2C', marginLeft: 20 }}
                className="text-white text-sm px-3 py-1 rounded w-20"
                onClick={handlePayButtonClick}
            >
                Pay
          </button>

          </div>
        </div>
      </form>
      }

    </div>
  )
} 

export default Step4MpesaScreen;

