import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
//import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Step4MpesaScreen from '../components/Step4MpesaScreen';

const Step4Payment = ({ 
  checkedAddProductItems,
  formDataStep0EvacuationRepatriation,
  formDataStep0LastExpense,
  formDataStep0Medical,
  formDataStep0HospitalCash,
  formDataStep0PersonalAccident,
  contactAndLoginsAndCurrency
}) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [screen, setScreen] = useState('paymentHome')

  const handleRadioButtonsChange = (e) => {
    setPaymentMethod(e.target.value)
  };

  const handlePayButtonClick = () => {
    if (paymentMethod === 'mpesa') {setScreen('mpesa');}
    else if (paymentMethod === 'stripe') {stripePayment();}
    else if (paymentMethod === '') {alert("Select a payment method");}
  };

  const calculateTotalPremium = () => {
    return [
      checkedAddProductItems_payment.evacuationRepatriation ? formDataStep0EvacuationRepatriation.totalPremium : 0,
      checkedAddProductItems_payment.lastExpense ? formDataStep0LastExpense.totalPremium : 0,
      checkedAddProductItems_payment.medical ? formDataStep0Medical.totalPremium : 0,
      checkedAddProductItems_payment.hospitalCash ? formDataStep0HospitalCash.totalPremium : 0,
      checkedAddProductItems_payment.personalAccident ? formDataStep0PersonalAccident.totalPremium : 0
    ]
      .map(value => parseFloat(value) || 0) // Convert to float and use 0 if NaN
      .reduce((acc, curr) => acc + curr, 0) // Sum up the values
      .toFixed(2) // Format the result to 2 decimal places
  }

  const [checkedAddProductItems_payment, setCheckedAddProductItems_payment] = useState(checkedAddProductItems);
  // Define a function to handle checkbox changes
  const handleCheckboxAddProduct_payment = (event) => {
    const { name, checked } = event.target;
    setCheckedAddProductItems_payment((prevCheckedAddProductItems_payment) => ({
      ...prevCheckedAddProductItems_payment,
      [name]: checked,
    }));
    console.log(checkedAddProductItems_payment.evacuationRepatriation ? formDataStep0EvacuationRepatriation.totalPremium : 0);
  };


  // Stripe

  const stripePromise = loadStripe('pk_test_51P7I9VSDdxbgQzLgVUWXvoe8DGSHI6tpLiqRj8s9ct5tGQs3lzXcpB63pEMB6yrxBnR1Q2VE3zyWmoKiAsJaFhJ500GZmD4p6i'); // Replace with your actual publishable key
// Function to handle the Stripe payment
const stripePayment = async () => {
  const stripe = await stripePromise; // Wait for stripe to initialize

  const data = {
    Currency: contactAndLoginsAndCurrency.currency,
    Name: 'Total amount due',
    UnitAmount: parseInt(calculateTotalPremium()) * 100,
    Quantity: 1,
  };

  // Fetch the Stripe Checkout session ID from your backend
    //fetch('http://localhost:5000/checkout-session', {
    fetch('http://api.mpesa.birdviewinsurance.com/checkout-session', {
       method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      console.log(data.id);
      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }
      return response.json();
    })
    .then((data) => {
      // Redirect to Stripe Checkout with the session ID
      stripe
        .redirectToCheckout({
          sessionId: data.id,
        })

        .then((result) => {
          if (result.error) {
            console.error('Stripe Checkout error:', result.error.message);
          }
        });
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

  return (
    <>
      {screen === 'paymentHome' &&
      <div className="shadow-div grid grid-cols-2">
        <div className="border-r pt-2 border-gray-300 pr-5 mr-5">
          <h5 className='font-semibold mb-2'>Selected Product(s)</h5>

          {checkedAddProductItems.evacuationRepatriation ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="evacuationRepatriation"
                  checked={checkedAddProductItems_payment.evacuationRepatriation}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Evacuation and Repatriation"
            />
            <span>{formDataStep0EvacuationRepatriation.totalPremium}</span>
          </div>
          : null}
          {checkedAddProductItems.lastExpense ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="lastExpense"
                  checked={checkedAddProductItems_payment.lastExpense}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Last Expense"
            />
            <span>{formDataStep0LastExpense.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.medical ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="medical"
                  checked={checkedAddProductItems_payment.medical}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Medical"
            />
            <span>{formDataStep0Medical.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.hospitalCash ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="hospitalCash"
                  checked={checkedAddProductItems_payment.hospitalCash}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Hospital Cash"
            />
            <span>{formDataStep0HospitalCash.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.personalAccident ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="personalAccident"
                  checked={checkedAddProductItems_payment.personalAccident}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Personal Accident"
            />
            <span>{formDataStep0PersonalAccident.totalPremium}</span>
          </div>
          :null}

          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <span className='font-semibold mb-2'>Total: </span>
            <span>{calculateTotalPremium()}</span>
          </div>
        </div>

        <div className="pt-2 pr-5 mr-5 flex items-center flex-col">
          <h5 className='font-semibold mb-2'>Payment Method</h5>

          <FormControl className="flex-grow">
            <RadioGroup value={paymentMethod} onChange={handleRadioButtonsChange}>
              <FormControlLabel value="mpesa" control={<Radio />} label="M-Pesa" />
              <FormControlLabel value="pesapal" control={<Radio />} label="Pesapal" />
              <FormControlLabel value="stripe" control={<Radio />} label="Stripe" />
            </RadioGroup>
          </FormControl>

          <button
            type="button"
            style={{backgroundColor:'#E42D2C', marginLeft:20}}
            className="text-white text-sm px-3 py-1 rounded w-20"
            onClick={handlePayButtonClick}
          >
            Pay
          </button>
        </div>
      </div>
      }

      {screen === 'mpesa' &&
        <Step4MpesaScreen />
      }
      {screen === 'stripe' &&
        <div>{}</div>
      }
    </>
  )
}

export default Step4Payment;
